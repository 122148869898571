@import '~@fortawesome/fontawesome-free/css/all.css' ;
@import './scss/argon/argon.scss' ;
@import "./scss/preloader.scss";
@import "~flag-icon-css/css/flag-icon.css";
.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand > img{
max-height:inherit;
}

.sidebar{
    .nav-link{
      padding:inherit;
      padding-bottom: 5px;
      padding-top: 5px;
    }
  }


#sidenav-main{
    padding-left:10px;
    overflow-x: hidden;
    padding-top:20px;
  }

html, body, #root, .App,.main-content{
  height:100%;
}
ul.no-decor{
  padding:0;
  list-style-type: none;
  li{
    margin: 0;

  }
}

.contractorBtn {
  min-width: 300px;
  text-align: left !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.companyField {
  background-color: #f7f7fa;
  color: #c5c6c7;
  cursor: not-allowed;
}

.card-balance {

  .rs-panel-body {
    padding-top: 0;
  }

  .ammount {
    font-size: 38px;
    font-weight: 700;
  }
}
